import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = ["| 5G 28GHz을 통한 혁신 서비스 제공과 소비자 후생 증대 추진", "| 초고속, 초저지연, 초연결의 리얼 5G구현"]

const content = () => (
  <div>
    <br />

    <p>
      1월 31일 21시, 50회의 오름입찰과 밀봉입찰 끝에 스테이지엑스 컨소시엄(가칭, 이하 스테이지엑스)이 제4 이동통신사업자로 최종
      선정되었다. 스테이지엑스는 입찰 시 배포한 보도자료를 통해 △국내 통신시장 경쟁활성화 △가계통신비 절감 △5G 28㎓ 기반 혁신
      생태계 구축이라는 3대 목표를 수립하고, 국가차원의 5G 기술을 선도하는 기업으로 자리매김할 것이라는 포부를 밝힌 바 있다.
      <br />
      <br />
      혁신적 통신테크 기업을 지향하는 스테이지엑스는 ‘초고속’, ‘초저지연’, ‘초연결’의 ‘Real 5G 서비스’ 구현을 위해 28GHz 핫스팟과
      더불어, 클라우드 코어망과 기존 통신3사 네트워크를 이용한 로밍을 통해 전국을 커버하는 5G서비스를 제공할 계획이다.
      <br />
      <br />
      구체적으로, 전략적 제휴 기업들과 함께 혁신적 요금제와 서비스를 설계, 보급하여 가계통신비 부담을 완하하고 28GHz 서비스 이용을
      위한 단말기 보급을 위해서는 국내 대표적 사업자인 삼성은 물론, 애플, 구글, 폭스콘 등과 전략적 제휴를 통해 5G 28㎓대역을
      지원하는 단말기를 보급할 계획이다.
      <br />
      <br />
      28GHz Real 5G 서비스 관련해서는 KAIST(과학기술원)와는 리빙랩 형태로, 연세의료원(세브란스)과는 디지털 기반 스마트병원 사업을
      추진한다. 엔터테인먼트 분야에서는 국내 주요 경기장 및 공연장과 협업하여 실감형 K-콘텐츠를 서비스할 예정이다. 더불어 공항 등
      다중이 이용하는 공공시설에서 28Ghz Real 5G 서비스 구현을 통해 통신강국이라는 국가브랜드 이미지 강화에도 기여한다는 입장이다.
      <br />
      <br />
      서상원 대표는 “5G서비스 활성화를 통해 통신시장에서 새로운 브랜드를 부각시키고, 시장에도 새롭고 혁신적인 변화를 줄 수 있도록
      노력하겠다”고 밝혔다. <br />
      <br />
      최종 결정된 주파수 대금 4,301억원은 당초 예상보다 높은 수준에서 결정되었지만, 스테이지엑스 관계자는 "단순 입찰가를 기준으로
      가격의 적정성을 판단하기보다는, ‘스테이지엑스의 제4이동통신사업자 자격 획득’에 큰 의미가 있다.”고 설명하고, “28GHz 주파수의
      독점적 사용으로 창출할 수 있는 다양한 서비스 및 기술, 그리고 부가가치를 반영한 미래가치를 고려하여 경매가를 결정하였다"고
      설명하였다. <br />
      <br />
      또한, “스테이지엑스가 도모할 온라인 기반의 이동통신 서비스 유통구조 혁신, 그리고 클라우드를 활용한 인프라 비용절감 측면까지
      감안한다면 사업성을 충분히 확보할 수 있을 것”이라고 말했다. <br />
      <br />
      스테이지엑스의 신규 시장 진입에 따라 그간 누릴 수 없었던 28㎓ 기반 Real 5G서비스 이용기회가 소비자들에게 제공되고,
      통신사업자 간의 경쟁 활성화를 통해 소비자 후생까지 증대될 것으로 기대된다. <br />
      <br />
    </p>
  </div>
)

const press48 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2024년 1월 31일 즉시배포 가능"
      pageInfo=""
      title="스테이지엑스, 제4이동통신사업자로 선정"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press48
